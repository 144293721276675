import React, { Fragment } from 'react';
import { compose } from 'recompose';

import Layout from '../components/layout';
import {
  withAuthorization,
  withEmailVerification,
} from '../components/Session';
import { UserList } from '../components/Users';
import * as ROLES from '../constants/roles';

const AdminPageBase = () => (
  <Fragment>
    <div className="page-content pb-0">
      <div className="card" data-card-height="cover" style={{height: '100vh'}}>
        <div className="card-center text-center">
          <div className="content mr-5 ml-5 mb-0">
            <h1>Admin</h1>
            <p>The Admin Page is accessible by every signed in admin user.</p>
            <UserList />
          </div>
        </div>
      </div>
    </div>
  </Fragment>
);

const condition = authUser =>
  authUser && !!authUser.roles[ROLES.ADMIN];

const AdminPage = compose(
  withEmailVerification,
  withAuthorization(condition),
)(AdminPageBase);

export default () => (
  <Layout>
    <br/><br/><br/><br/><br/>
    <AdminPage />
  </Layout>
);
